import i18next from 'i18next';
import { FC, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import Checkbox from '../../../components/shared/form-control/Checkbox';
import { Input } from '../../../components/shared/form-control/Input';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import AuthService from '../../../services/AuthService';
import { FlowProps } from './RegisterFlow';

const RegisterDetails: FC<FlowProps> = (props) => {
  const { next, userEmail } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [tcAgree, setTcAgree] = useState(false);

  const { t } = useTranslation('auth');

  const valid = useMemo(() => firstName && lastName && tcAgree, [firstName, lastName, tcAgree]);

  const saveDetails = useCallback(() => {
    if (valid) {
      AuthService.setUserDetails({ firstName, lastName, jobTitle, phoneNumber: contactNumber, languageCode: i18next.language }).then(() => {
        next();
      });
    }
  }, [contactNumber, firstName, jobTitle, lastName, next, valid]);

  return (
    <>
      <Heading size={HeadingSize.H1}>{t('registration.details.heading')}</Heading>
      <div>{t('registration.details.subheading')}</div>
      <div className="w-96">
        <Input value={userEmail} label={t('registration.details.inputs.email')} disabled data-cy="registration-email" />
        <Input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder={t('registration.details.inputs.first-name')}
          label={t('registration.details.inputs.first-name')}
          data-cy="registration-first-name"
        />
        <Input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder={t('registration.details.inputs.last-name')}
          label={t('registration.details.inputs.last-name')}
          data-cy="registration-last-name"
        />
        <Input
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          placeholder={t('registration.details.inputs.job-title')}
          label={t('registration.details.inputs.job-title')}
          data-cy="registration-job-title"
        />
        <Input
          value={contactNumber}
          onChange={(e) => setContactNumber(e.target.value)}
          placeholder={t('registration.details.inputs.contact-number')}
          label={t('registration.details.inputs.contact-number')}
          data-cy="registration-contact-number"
        />
        <div>
          <Checkbox
            value={tcAgree}
            onChange={setTcAgree}
            label={
              <Trans
                t={t}
                i18nKey="registration.details.inputs.t-and-c"
                components={{
                  Link: (
                    <a
                      href="/legal/terms-and-conditions"
                      data-cy="registration-terms-and-conditions-link"
                      className="cursor-pointer font-medium hover:underline"
                      target="_blank"
                    />
                  ),
                }}
              />
            }
            className="mt-4"
            data-cy="registration-terms-and-conditions"
          />
        </div>
        <div className="px-10 text-center">
          <Button className="mt-6 w-full" type={ButtonType.PRIMARY} onClick={saveDetails} data-cy="register-next" disabled={!valid}>
            <span className="font-medium">{t('registration.details.buttons.next')}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default RegisterDetails;
